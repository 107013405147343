import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useAxios } from '../../api/zw-service';
import { isNotNullish } from '../../utils/objects.util';

const Verification: React.FC = () => {
  const { code: verificationCode } = useParams();
  const [verificationStatus, setVerificationStatus] = React.useState('');
  const { verifyLandemicMail } = useAxios();
  useQuery(
    ['landemic', verificationCode],
    async () => {
      if (isNotNullish(verificationCode)) {
        const response = await verifyLandemicMail(verificationCode as string);
        if (response.status === 200) {
          return response.data;
        }
        return null;
      }
    },
    {
      onSuccess: () =>
        setVerificationStatus('Danke, dass du dich verifiziert hast!'),
      onError: () =>
        setVerificationStatus('Deine Verifizierung ist leider fehlgeschlagen!'),
    }
  );

  return verificationStatus ? (
    <div className="container bg-dark pt-3 px-5">
      <div className="row" style={{ marginTop: '30px' }}>
        <div className="col-12 d-flex mb-1">
          <h3>{verificationStatus}</h3>
        </div>
      </div>
      <div className="row" style={{ marginTop: '30px' }}>
        <div className="col-12 d-flex mb-3">
          <h4>Diese Seite kann geschlossen werden...</h4>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Verification;

import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import getAppRouter from "./app-router";

const rootElement = document.getElementById("root") as HTMLElement;
const reactAppRoot = ReactDOM.createRoot(rootElement);
const queryClient = new QueryClient();
const appRouter = getAppRouter();

reactAppRoot.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider
        router={appRouter}
        fallbackElement={<div>Loading...</div>}
      />
    </QueryClientProvider>
  </React.StrictMode>
);

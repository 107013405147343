import { useMemo } from "react";

const useCookie = () => {
  const isAccepted = useMemo(() => {
    return window.localStorage.getItem("sl_track_cookies") === "ja";
  }, [window.localStorage.getItem("sl_track_cookies")]);

  return {
    isAccepted,
  };
};

export default useCookie;

import { LandemicFlyerListGroupItem } from '../components/LandemicFlyer';

export type LandemicInfos = Array<{
  lines: React.ComponentProps<typeof LandemicFlyerListGroupItem>[];
  header: string;
}>;

export const LANDEMIC_INFOS: LandemicInfos = [
  {
    lines: [
      {
        label: 'Ort',
        value:
          'Zweibrücken - ehem. Hauptschule Nord (Hofenfelsstraße 53) - Raum 305',
      },
      { label: 'Datum', value: '10. - 11. Dezember 2022' },
      { label: 'Aufbau', value: 'Samstag (10.12.) 09:00 Uhr' },
      { label: 'Beginn', value: 'Samstag (10.12.) 10:00 Uhr' },
      { label: 'Ende (offiziell)', value: 'Sonntag (11.12.) 20:00 Uhr' },
      { label: 'Eintritt', value: '25€' },
      { label: 'Mindestalter', value: '16 Jahre' },
    ],
    header: 'Allgemeines',
  },
  {
    lines: [
      { label: 'Verfügbare Plätze', value: 'Max. 12' },
      { label: 'Snacks', value: 'werden gestellt' },
      {
        label: 'Getränke',
        value: 'Getränkeverkauf - Getränke mitbringen erlaubt',
      },
    ],
    header: 'Organisation',
  },
  {
    lines: [
      { content: 'Ausweis' },
      { content: 'PC' },
      { content: 'Tastatur' },
      { content: 'Maus' },
      { content: 'Headset' },
      { content: 'Bildschirm (kann ggf. auch gestellt werden)' },
    ],
    header: 'Mitbringen',
  },
  {
    lines: [
      { content: 'Counter-Strike 1.6' },
      { content: 'Minecraft (jeder selbst)' },
      { content: 'Empire Earth 1 + Space Erweiterung' },
      { content: 'Empire Earth 2 + Space Erweiterung' },
      { content: 'Halo CE' },
      { content: 'Starcraft' },
      { content: 'Warcraft (und DOTA)' },
      { content: 'C&C Red Alert 2' },
      { content: 'C&C Generals' },
      { content: 'Team Fortress 2' },
      { content: 'Doom' },
      { content: 'Switch (Mario Kart 8)' },
    ],
    header: 'Spiele',
  },
];

import { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { DocumentCallback } from "react-pdf/dist/cjs/shared/types";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Drinkingmenu: React.FC = () => {
  const [pageCount, setPageCount] = useState<number | null>(null);

  function onDocumentLoadSuccess(document: DocumentCallback): void {
    const { numPages } = document;
    setPageCount(numPages);
  }

  const [initialWidth, setInitialWidth] = useState<number>(0);

  const pdfWrapper = useRef<HTMLDivElement>(null);

  const setPdfSize = () => {
    if (pdfWrapper && pdfWrapper.current) {
      setInitialWidth(pdfWrapper.current.offsetWidth);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", setPdfSize);
    setPdfSize();
    return () => {
      window.removeEventListener("resize", setPdfSize);
    };
  }, []);

  return (
    <div ref={pdfWrapper} className="pdf-wrapper">
      <Document
        file={`${process.env.PUBLIC_URL}/drinkingmenu.pdf`}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={(error) => console.log(error)}
      >
        {Array.from(new Array(pageCount), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={initialWidth}
          />
        ))}
      </Document>
    </div>
  );
};

export default Drinkingmenu;

import { PagesEnabled } from "./page-types";

const pagesEnabled: PagesEnabled = {
  main: true,
  landemic: false,
  landemicVerification: false,
  it2bib: false,
  privacyPolicy: true,
  blogposts: true,
  drinkingmenu: false,
};

export default pagesEnabled;

import HomeComponent from "./components/Home";
import AboutComponent from "./components/About";
import ContactComponent from "./components/Contact";
import ImprintComponent from "./components/Imprint";
import ReturnToHomeComponent from "./components/ReturnToHome";
import LandemicFlyerComponent, {
  LandemicFlyerHeader,
  LandemicFlyerListItems,
} from "./components/LandemicFlyer";
import VerificationComponent from "./components/Verification";
import { PageComponentsAvailable } from "./page-types";
import LandemicSignUpForm from "./components/LandemicFlyer/signup-form";
import It2BibAgenda, {
  It2BibFlyerHeader,
  It2BibFlyerListItems,
} from "./components/IT2Bib";
import PrivacyPolicy from "./components/DSGVO";
import BlogPostsComponent from "./components/BlogPosts";
import Drinkingmenu from "./components/Drinkingmenu";

const componentMap: PageComponentsAvailable = {
  home: HomeComponent,
  about: AboutComponent,
  contact: ContactComponent,
  imprint: ImprintComponent,
  "return-to-home": ReturnToHomeComponent,
  "landemic-flyer": LandemicFlyerComponent,
  "landemic-verification": VerificationComponent,
  "landemic-flyer-header": LandemicFlyerHeader,
  "landemic-flyer-info-list": LandemicFlyerListItems,
  "landemic-signup-form": LandemicSignUpForm,
  "it2bib-flyer": It2BibAgenda,
  "it2bib-flyer-header": It2BibFlyerHeader,
  "it2bib-flyer-info-list": It2BibFlyerListItems,
  "privacy-policy": PrivacyPolicy,
  blogposts: BlogPostsComponent,
  drinkingmenu: Drinkingmenu,
};

export default componentMap;

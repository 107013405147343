import React, { useEffect } from "react";
import { Field, Form } from "react-final-form";
import * as yup from "yup";
import "./index.css";
import { toast } from "react-toastify";
import { Contact as ContactForm, useAxios } from "../../api/zw-service";
import { validateFinalForm } from "../../utils/form.util";
import { useElementOnScreen } from "../../hooks/use-element-on-screen";
import useCookie from "../../hooks/use-cookie";

const Contact: React.FC = () => {
  const [rowOneRef, rowOneInView] = useElementOnScreen<HTMLDivElement>({
    root: null,
    rootMargin: "0px",
    threshold: 0.01,
  });
  const validationSchema = yup.object({
    name: yup.string().required("Bitte gib einen Namen ein!"),
    email: yup
      .string()
      .email("Bitte gib eine gültige E-Mail ein!")
      .required("Bitte gib eine E-Mail Adresse ein!"),
    message: yup
      .string()
      .required("Bitte teile uns etwas mit!")
      .min(10, "Deine Nachricht sollte mindestend 10 Zeichen lang sein.")
      .max(1500, "Deine Nachricht sollte maximal 1500 Zeichen lang sein."),
  });

  useEffect(() => {
    const handleRefMapVisibility = (refMap: {
      ref: React.MutableRefObject<HTMLDivElement | null>;
      visible: boolean;
    }) =>
      refMap.visible
        ? refMap.ref.current?.classList.add("fade-in")
        : refMap.ref.current?.classList.remove("fade-in");
    [{ ref: rowOneRef, visible: rowOneInView }].forEach(handleRefMapVisibility);
  }, [rowOneRef, rowOneInView]);

  const { contact } = useAxios();

  const { isAccepted } = useCookie();

  return (
    <div>
      <div className="container px-md-5 mt-5">
        <div className="row mt-5">
          <h2>Wie kann man uns kontaktieren?</h2>
        </div>

        <Form<ContactForm>
          validate={validateFinalForm(validationSchema)}
          onSubmit={async (props, form) => {
            try {
              const response = await contact(props);
              if (response.status === 201) {
                form.restart();
                toast.success("Deine Nachricht wurde erfolgreich versendet!");
              }
            } catch (error) {
              toast.error("Deine Nachricht konnte nicht versendet werden!");
            }
          }}
        >
          {({ handleSubmit, invalid, pristine }) => (
            <>
              <div className="row mt-4">
                <h6>
                  Du kannst du uns direkt hier über die Homepage kontaktieren!
                  Einfach das Kontaktformular ausfüllen und abschicken.
                </h6>
              </div>
              <div className="row mt-4">
                <div className="input-group mb-2">
                  <Field name="name">
                    {({ input, meta }) => (
                      <>
                        <span
                          className="input-group-text"
                          style={{ width: "7rem" }}
                          id="basic-addon1"
                        >
                          Name
                        </span>
                        <input className="form-control" {...input} />
                        {meta.error && meta.touched && (
                          <div className="col-12">
                            <span style={{ color: "red", fontSize: "0.8rem" }}>
                              {meta.error}
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  </Field>
                </div>

                <div className="input-group mb-2">
                  <Field name="email">
                    {({ input, meta }) => (
                      <>
                        <span
                          className="input-group-text"
                          id="basic-addon1"
                          style={{ width: "7rem" }}
                        >
                          E-Mail
                        </span>
                        <input className="form-control" {...input} />
                        {meta.error && meta.touched && (
                          <div className="col-12">
                            <span style={{ color: "red", fontSize: "0.8rem" }}>
                              {meta.error}
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  </Field>
                </div>

                <div className="input-group">
                  <Field name="message">
                    {({ input, meta }) => (
                      <>
                        <span
                          className="input-group-text"
                          style={{ width: "7rem" }}
                        >
                          Nachricht
                        </span>
                        <textarea
                          className="form-control"
                          {...input}
                          style={{ minHeight: "200px" }}
                        />
                        {meta.error && meta.touched && (
                          <div className="col-12">
                            <span style={{ color: "red", fontSize: "0.8rem" }}>
                              {meta.error}
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  </Field>
                </div>

                {/* Align input group to the right */}
                <div
                  className="input-group mt-2 mb-2"
                  style={{ width: "100%" }}
                >
                  {/* Align button to the right */}
                  <button
                    className="btn btn-primary"
                    type="submit"
                    style={{ marginLeft: "auto" }}
                    disabled={invalid || pristine}
                    onClick={handleSubmit}
                  >
                    Abschicken
                  </button>
                </div>
              </div>
            </>
          )}
        </Form>
        <div className="row" style={{ marginTop: "10rem" }} ref={rowOneRef}>
          <div className={"col-sm-12 col-md-6 mt-5"}>
            {isAccepted && (
              <iframe
                src="https://discord.com/widget?id=936233248940507186&theme=dark"
                height="360"
                width={"100%"}
                sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
              />
            )}
          </div>
          <div className={"col-sm-12 col-md-6 mt-5"}>
            <p className="d-flex align-items-center h-100">
              Außerdem kannst du unserem Discord-Server beitreten, um mit uns in
              Kontakt zu treten und um mehr über unsere Aktivitäten zu erfahren.
              Wir freuen uns darauf, dich dort zu sehen!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

/* eslint-disable indent */
import { RouteObject, createBrowserRouter } from "react-router-dom";
import { appContentConfig } from "./component-configs/pages-config";
import { PageName, PageNavbarElementOptions } from "./page-types";
import pagesEnabled from "./pages-enabled";
import pageRoutes from "./page-routes";
import App from "./App";
import Navbar from "./components/Navbar";
import ErrorComponent from "./components/Error";

const getAvailablePageNames = () => Object.keys(appContentConfig) as PageName[];
const isEnabled = (pageName: PageName) => pagesEnabled[pageName];
const getPageRoute = (pageName: PageName) => pageRoutes[pageName];
const getPageNavigationElementOptions = (
  pageName: PageName
): PageNavbarElementOptions => ({
  visibleInNavbar: appContentConfig[pageName].visibleInNavbar,
  displayName: appContentConfig[pageName].displayName || "Unterseite",
});
const getPageRouteElement = (pageName: PageName) => {
  const navbarItems = Object.keys(appContentConfig).reduce((acc, pageName) => {
    return !isEnabled(pageName as PageName) ||
      !getPageNavigationElementOptions(pageName as PageName).visibleInNavbar
      ? acc
      : [
          ...acc,
          {
            ...getPageNavigationElementOptions(pageName as PageName),
            route: getPageRoute(pageName as PageName),
          },
        ];
  }, [] as (PageNavbarElementOptions & { route: string })[]);
  return (
    <>
      <Navbar items={navbarItems} />
      <App currentPage={appContentConfig[pageName]} />
    </>
  );
};
const toRouteObject: (pageName: PageName) => RouteObject = (
  pageName: PageName
) => ({
  path: getPageRoute(pageName),
  element: getPageRouteElement(pageName),
  errorElement: <ErrorComponent />,
});

const getRouteObjects = () =>
  getAvailablePageNames().filter(isEnabled).map(toRouteObject);

const getAppRouter = () => createBrowserRouter(getRouteObjects());

export default getAppRouter;

import { useEffect, useRef } from "react";
import Navbar from "../Navbar";

class GameOfLife {
  cell_size: number;
  dead_color: string;
  alive_color: string;
  cells_in_column: number;
  cells_in_rows: number;
  active_array: number[][];
  inactive_array: number[][];
  arrayInitialization: () => void;
  arrayRandomize: () => void;
  fillArray: () => void;
  setCellValueHelper: (row: number, col: number) => number;
  countNeighbours: (row: number, col: number) => number;
  updateCellValue: (row: number, col: number) => number;
  updateLifeCycle: () => void;
  gameSetUp: () => void;
  runGame: () => void;
  /*
    functions 
        1 - create 2 2d arrays with zeros (active/inactive) - done!
        2 - fill active array randomly with ones and zeros - done! 
        3 - set color for cells - done! 
        4 - count neigbours 
        5 - update generation 
        6 - clear canvas
    */

  constructor(canvas: HTMLCanvasElement) {
    this.cell_size = 5;
    this.dead_color = `#181818`;
    this.alive_color = `#FF756B`;
    this.cells_in_column = Math.floor(canvas.width / this.cell_size);
    this.cells_in_rows = Math.floor(canvas.height / this.cell_size);
    this.active_array = [];
    this.inactive_array = [];

    this.arrayInitialization = () => {
      for (let i = 0; i < this.cells_in_rows; i++) {
        this.active_array[i] = [];
        for (let j = 0; j < this.cells_in_column; j++) {
          this.active_array[i][j] = 0;
        }
      }
      this.inactive_array = this.active_array;
    };

    this.arrayRandomize = () => {
      for (let i = 0; i < this.cells_in_rows; i++) {
        for (let j = 0; j < this.cells_in_column; j++) {
          this.active_array[i][j] = Math.random() > 0.5 ? 1 : 0;
        }
      }
    };

    this.fillArray = () => {
      const ctx = canvas.getContext("2d");
      const bufferCanvas = document.createElement("canvas");
      bufferCanvas.width = canvas.width;
      bufferCanvas.height = canvas.height;
      const bufferCtx = bufferCanvas.getContext("2d");

      if (bufferCanvas && bufferCtx && ctx) {
        for (let i = 0; i < this.cells_in_rows; i++) {
          for (let j = 0; j < this.cells_in_column; j++) {
            let color;
            if (this.active_array[i][j] == 1) color = this.alive_color;
            else color = this.dead_color;
            bufferCtx.fillStyle = color;
            bufferCtx.fillRect(
              j * this.cell_size,
              i * this.cell_size,
              this.cell_size,
              this.cell_size
            );
          }
        }

        ctx.drawImage(bufferCanvas, 0, 0);
      }
    };

    this.setCellValueHelper = (row, col) => {
      try {
        return this.active_array[row][col];
      } catch {
        return 0;
      }
    };

    this.countNeighbours = (row, col) => {
      let total_neighbours = 0;
      total_neighbours += this.setCellValueHelper(row - 1, col - 1);
      total_neighbours += this.setCellValueHelper(row - 1, col);
      total_neighbours += this.setCellValueHelper(row - 1, col + 1);
      total_neighbours += this.setCellValueHelper(row, col - 1);
      total_neighbours += this.setCellValueHelper(row, col + 1);
      total_neighbours += this.setCellValueHelper(row + 1, col - 1);
      total_neighbours += this.setCellValueHelper(row + 1, col);
      total_neighbours += this.setCellValueHelper(row + 1, col + 1);
      return total_neighbours;
    };

    this.updateCellValue = (row, col) => {
      const total = this.countNeighbours(row, col);
      // cell with more than 4 or less then 3 neighbours dies. 1 => 0; 0 => 0
      if (total > 4 || total < 3) {
        return 0;
      }
      // dead cell with 3 neighbours becomes alive. 0 => 1
      else if (this.active_array[row][col] === 0 && total === 3) {
        return 1;
      }
      // or returning its status back. 0 => 0; 1 => 1
      else {
        return this.active_array[row][col];
      }
    };

    this.updateLifeCycle = () => {
      for (let i = 0; i < this.cells_in_rows; i++) {
        for (let j = 0; j < this.cells_in_column; j++) {
          const new_state = this.updateCellValue(i, j);
          this.inactive_array[i][j] = new_state;
        }
      }
      this.active_array = this.inactive_array;
    };

    this.gameSetUp = () => {
      this.arrayInitialization();
    };

    this.runGame = () => {
      this.updateLifeCycle();
      this.fillArray();
    };
  }
}

const ErrorComponent: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current as HTMLCanvasElement;
    const game = new GameOfLife(canvas);
    game.gameSetUp();
    game.arrayRandomize();
    game.fillArray();
    const interval = setInterval(() => {
      game.runGame();
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [canvasRef]);

  return (
    <>
      <Navbar items={[]} />
      <div className="container">
        <div className="row my-5">
          <h1>404 - Page not found</h1>
          <p>
            Tut uns Leid, aber die Seite konnte nicht gefunden werden. Wir haben
            dir aber eine kleine Simulation mitgebracht. Das ist ein Game of
            Life. Es ist ein Zelluläres Automatenspiel, das von John Horton
            Conway entwickelt wurde. Es ist ein Spiel, bei dem die Zellen auf
            einem Raster entweder lebendig oder tot sind.
            <br />
            In jeder Generation werden die Zellen aufgrund der Anzahl ihrer
            lebenden Nachbarn neu berechnet.
            <br />
            Dabei gelten folgende Regeln:
          </p>
          <ol>
            <li style={{ color: "#ebe534" }}>
              Jede lebende Zelle mit weniger als zwei lebenden Nachbarn stirbt
              (als Unterbevölkerung oder Exposition bezeichnet).
            </li>
            <li>
              Jede lebende Zelle mit mehr als drei lebenden Nachbarn stirbt
              (Überbevölkerung oder Overcrowding genannt).
            </li>
            <li style={{ color: "#ebe534" }}>
              Jede lebende Zelle mit zwei oder drei lebenden Nachbarn lebt
              unverändert in der nächsten Generation weiter.
            </li>
            <li>
              Jede tote Zelle mit genau drei lebenden Nachbarn wird wieder zum
              Leben erweckt.
            </li>
          </ol>
          <canvas width={400} height={400} id="canvas" ref={canvasRef}></canvas>
        </div>
      </div>
    </>
  );
};

export default ErrorComponent;

import axios from 'axios';
import { ENV_CONFIG } from '../config';

const zwServiceClient = axios.create({
  baseURL: ENV_CONFIG.serverUrl,
});

export type LandemicSignUp = { name: string; email: string; birthday: string };
export type Contact = { name: string; email: string; message: string };

// create hook for axios
export const useAxios = () => {
  return {
    verifyLandemicMail: async (code: string) =>
      await zwServiceClient.post(`/api/v1/landemic/verify/${code}`),
    signUpLandemic: async (signUpProps: LandemicSignUp) =>
      await zwServiceClient.post('/api/v1/register/landemic', signUpProps),
    contact: async (contactProps: Contact) =>
      await zwServiceClient.post('/api/v1/contact', contactProps),
  };
};

import React from "react";
import { Link } from "react-scroll";

const ReturnToHome: React.FC = () => {
  return (
    <div className="row mb-5">
      <div className="col-12 d-flex justify-content-center mb-5 mt-5">
        <Link to="home" spy={true} smooth={true} duration={500}>
          <button className="btn btn-warning mb-5">↑ Zurück gehen ↑</button>
        </Link>
      </div>
    </div>
  );
};

export default ReturnToHome;

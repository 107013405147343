import React from "react";
import "./index.css";
import logo from "../../assets/logo_only_big_white_transparent.svg";
import { PageNavbarElementOptions } from "../../page-types";
import { Link, useHref, useLinkClickHandler } from "react-router-dom";

export type NavbarProps = {
  items: Array<PageNavbarElementOptions & { route: string }>;
};

export const Navbar: React.FC<NavbarProps> = ({ items }) => {
  const brandAnchorHref = useHref("/");
  const handleBrandAnchorClick = useLinkClickHandler("/");
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark px-2 sticky-top">
        <a
          href={brandAnchorHref}
          onClick={handleBrandAnchorClick}
          style={{ cursor: "pointer", margin: 0, padding: 0 }}
          className="navbar-brand"
        >
          {logo && (
            <img
              src={logo}
              style={{
                margin: 0,
                padding: 0,
                maxHeight: 70,
                maxWidth: 70,
              }}
              alt="logo"
              className="logo"
            />
          )}
        </a>
        <button
          className="navbar-toggler ms-auto"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          style={{ background: "transparent", border: "none" }}
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            {items.map(({ displayName, route }) => (
              <li className="nav-item" key={route}>
                <Link to={route} className="nav-link">
                  {displayName}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </nav>
      <div className="navbar-spacer"></div>
    </>
  );
};

export default Navbar;

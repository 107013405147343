/* eslint-disable @typescript-eslint/no-explicit-any */
import { setIn } from 'final-form';
import React, { useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { LandemicSignUp, useAxios } from '../../api/zw-service';
import { useElementOnScreen } from '../../hooks/use-element-on-screen';
import './index.css';

const LandemicSignUpForm: React.FC = () => {
  const validationSchema = yup.object({
    name: yup.string().required('Bitte gib einen Namen ein!'),
    email: yup
      .string()
      .email('Bitte gib eine gültige E-Mail ein!')
      .required('Bitte gib eine E-Mail Adresse ein!'),
    birthday: yup
      .string()
      .required('Bitte gib dein Geburtsdatum ein!')
      .test(
        'age',
        'Du musst mind. 16 Jahre alt sein! Vor Ort wird eine Ausweiskontrolle durchgeführt.',
        (value) => {
          if (!value) return false;
          const date = new Date(value);
          // check age (16 years)
          const age = new Date().getFullYear() - date.getFullYear();
          return age >= 16;
        }
      ),
  });

  const validate = (schema: any) => {
    return async (values: any) => {
      if (typeof schema === 'function') schema = schema();

      try {
        await schema.validate(values, { abortEarly: false });
      } catch (e: any) {
        return e.inner.reduce((errors: any, error: any) => {
          return setIn(errors, error.path, error.message);
        }, {});
      }
    };
  };

  const { signUpLandemic } = useAxios();
  const [formContainerRef, isFormContainerVisible] =
    useElementOnScreen<HTMLDivElement>({
    	root: null,
    	rootMargin: '0px',
    	threshold: 0.3,
    });

  useEffect(() => {
    const handleRefMapVisibility = (refMap: {
      ref: React.MutableRefObject<HTMLDivElement | null>;
      visible: boolean;
    }) => {
      refMap.visible
        ? refMap.ref.current?.classList.add('fade-in-info-item')
        : refMap.ref.current?.classList.remove('fade-in-info-item');
    };
    [{ ref: formContainerRef, visible: isFormContainerVisible }].forEach(
      handleRefMapVisibility
    );
  }, [formContainerRef, isFormContainerVisible]);

  return (
    <div
      ref={formContainerRef}
      className="container fade-out-info-item"
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div className="container px-md-5">
        <div>
          <h5 className="mt-5">
            <strong className="flyer-text" style={{ fontSize: '1rem' }}>
              {'Anmeldung'}
            </strong>
          </h5>
        </div>
        <Form<LandemicSignUp>
          validate={validate(validationSchema)}
          initialValues={{
            birthday: new Date(
              new Date().setFullYear(new Date().getFullYear() - 16)
            )
              .toISOString()
              .split('T')[0],
          }}
          onSubmit={async (props, form) => {
            try {
              console.log(props);
              const response = await signUpLandemic(props);
              if (response.status === 201) {
                form.restart();
                toast.success('Deine Nachricht wurde erfolgreich versendet!');
              }
            } catch (error) {
              toast.error('Deine Nachricht konnte nicht versendet werden!');
            }
          }}
        >
          {({ handleSubmit, invalid, pristine }) => (
            <div className="row mt-3">
              <div className="input-group mb-2">
                <Field name="name">
                  {({ input, meta }) => (
                    <>
                      <span
                        className="input-group-text"
                        style={{ width: '8rem' }}
                        id="basic-addon1"
                      >
                        Name
                      </span>
                      <input className="form-control" {...input} />
                      {meta.error && meta.touched && (
                        <div className="col-12">
                          <span style={{ color: 'red', fontSize: '0.8rem' }}>
                            {meta.error}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </Field>
              </div>

              <div className="input-group mb-2">
                <Field name="email">
                  {({ input, meta }) => (
                    <>
                      <span
                        className="input-group-text"
                        id="basic-addon1"
                        style={{ width: '8rem' }}
                      >
                        E-Mail
                      </span>
                      <input className="form-control" {...input} />
                      {meta.error && meta.touched && (
                        <div className="col-12">
                          <span style={{ color: 'red', fontSize: '0.8rem' }}>
                            {meta.error}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </Field>
              </div>

              <div className="input-group mb-2">
                <Field name="birthday" type="date">
                  {({ input, meta }) => (
                    <>
                      <span
                        className="input-group-text"
                        id="basic-addon1"
                        style={{ width: '8rem' }}
                      >
                        Geburtstag
                      </span>
                      <input className="form-control" {...input} />
                      {meta.error && meta.touched && (
                        <div className="col-12">
                          <span style={{ color: 'red', fontSize: '0.8rem' }}>
                            {meta.error}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </Field>
              </div>

              <div className="input-group mt-2 mb-2" style={{ width: '100%' }}>
                <button
                  className="btn btn-primary"
                  type="submit"
                  style={{ marginLeft: 'auto' }}
                  disabled={invalid || pristine}
                  onClick={handleSubmit}
                >
                  Anmelden
                </button>
              </div>
            </div>
          )}
        </Form>
      </div>
    </div>
  );
};

export default LandemicSignUpForm;

import React, { useEffect } from "react";
import "./index.css";
import { useElementOnScreen } from "../../hooks/use-element-on-screen";
import { IT2BIB_INFOS } from "../../component-configs/it-2-bib-infos";

const It2BibAgenda: React.FC = () => {
  return (
    <div className="container">
      <div className="row mb-5">
        <div className="col-12">
          <h3>Workshop IT2Bib - Einstieg in Linux</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <p>
            Willkommen zum 12-stündigen Linux-Kurs, der am Mittwoch, 05. April
            im Raum 305 der ehem. Hauptschule Nord stattfindet. In
            Zusammenarbeit mit dem Verein{" "}
            {'"Kultur und Technik Zweibrücken e.V."'}
            und der Stadtbücherei Zweibrücken, bieten wir einen intensiven
            Workshop für alle interessierten Teilnehmer ab zwölf Jahren an, die
            den Einstieg in das Betriebssystem Linux erleben möchten.
          </p>
        </div>
        <div className="col-md-6">
          <p>
            An diesem zweitägigen IT-Workshop erwartet dich ein spannendes
            Programm, umfassende Einblicke in die Welt von Linux und jede Menge
            Möglichkeiten, deine Kenntnisse in der Linux-Shell, der
            Benutzerverwaltung und Netzwerk-Konfiguration zu erweitern. Freue
            dich auf ein tolles Gemeinschaftserlebnis mit anderen
            Linux-Begeisterten und lass dich von unseren erfahrenen Kursleitern
            auf eine einzigartige Entdeckungsreise durch die Welt von Linux
            mitnehmen.
          </p>
        </div>
      </div>
    </div>
  );
};

export const It2BibFlyerHeader: React.FC = () => {
  return (
    <div className="container card bg-dark">
      <div className="row">
        <div className="col-12">
          <a className="flyer-header">
            <div>IT-2-Bib</div>
          </a>
        </div>
      </div>
    </div>
  );
};

export const It2BibFlyerListItems: React.FC = () => {
  return (
    <div className="container card bg-dark">
      {IT2BIB_INFOS.map(({ lines, header }) => (
        <div key={header} className="row">
          <div className="col-12">
            <h5 className="card-title pt-3 pb-2">
              <strong className="flyer-text">{header}</strong>
            </h5>
            {lines.map((props, index) => (
              <It2BibFlyerListGroupItem key={index} {...props} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export type It2BibFlyerListGroupItemProps =
  | {
      label: string;
      value: string;
    }
  | { content: string };

const isContentElement = (
  props: It2BibFlyerListGroupItemProps
): props is { content: string } =>
  (props as { content: string }).content !== undefined;

export const It2BibFlyerListGroupItem: React.FC<
  It2BibFlyerListGroupItemProps
> = (props) => {
  const elementScrollOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0.3,
  };
  const [rowOneRef, rowOneInView] =
    useElementOnScreen<HTMLDivElement>(elementScrollOptions);
  useEffect(() => {
    const handleRefMapVisibility = (refMap: {
      ref: React.MutableRefObject<HTMLDivElement | null>;
      visible: boolean;
    }) => {
      refMap.visible
        ? refMap.ref.current?.classList.add("fade-in-info-item")
        : refMap.ref.current?.classList.remove("fade-in-info-item");
    };
    [{ ref: rowOneRef, visible: rowOneInView }].forEach(handleRefMapVisibility);
  }, [rowOneRef, rowOneInView]);

  return (
    <div
      ref={rowOneRef}
      className="row list-group-item bg-dark fade-out-info-item"
    >
      {isContentElement(props) ? (
        <div className="col-12">
          <span>{props.content}</span>
        </div>
      ) : (
        <>
          <div className="col-md-4">
            <span>{props.label}</span>
          </div>
          <div className="col-md-8">
            <span>{props.value}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default It2BibAgenda;

import { It2BibFlyerListGroupItem } from "../components/IT2Bib";

export type IT2BibInfos = Array<{
  lines: React.ComponentProps<typeof It2BibFlyerListGroupItem>[];
  header: string;
}>;

// 10:00-11:00 Uhr:
// Herzlich willkommen zum Linux-Kurs!
// Entdecke das Betriebssystem, das die Welt erobert hat
// Erfahre mehr über die Geschichte und die Hintergründe von Linux

// 11:00-12:30 Uhr:
// Lass uns Linux auf installieren!
// Erste Schritte mit dem Betriebssystem

// 12:30-13:30 Uhr:
// Pause! Nutze die Zeit, um dich mit deinen Mitstreitern auszutauschen

// 13:30-15:00 Uhr:
// Werde zum Linux-Profi: Dateien und Verzeichnisse wie ein Boss verwalten
// Entdecke die coolsten Linux-Anwendungen und was sie alles können. Richte dein eigenes System ein.

// 15:00-16:00 Uhr:
// Let's rock: Vertiefung des ersten Tages
// Abschlussdiskussion und jede Menge Raum für deine Fragen

// Tag 2 (10:00-16:00 Uhr)

// 10:00-11:00 Uhr:
// Zurück in der Linux-Welt: Recap des ersten Tages
// Entdecke die vielen Möglichkeiten der Linux

// 11:00-12:30 Uhr:
// Textverarbeitung mit Linux: So einfach und doch so mächtig
// Nutze Linux-Kommandos wie ein Profi und lass deine Freunde staunen

// 12:30-13:30 Uhr:
// Pause! Iss was, quatsche und lade deine Batterien wieder auf

// 13:30-15:00 Uhr:
// Verwaltung von Benutzern und Netzwerken: Zeig, was du drauf hast!
// Konfiguration von Netzwerkeinstellungen leicht gemacht

// 15:00-16:00 Uhr:
// Zusammenfassung und Feedback-Runde
// Lass uns den Kurs gemeinsam abschließen und tausche dich mit deinen Mitstreitern aus!

export const IT2BIB_INFOS: IT2BibInfos = [
  {
    lines: [
      {
        label: "10:00-11:00 Uhr",
        value:
          "Herzlich willkommen zum Linux-Kurs! - Entdecke das Betriebssystem, das die Welt erobert hat",
      },
      {
        label: "11:00-12:30 Uhr",
        value:
          "Lass uns Linux installieren! - Erste Schritte mit dem Betriebssystem",
      },
      {
        label: "12:30-13:30 Uhr",
        value:
          "Pause! Nutze die Zeit, um dich mit deinen Mitstreitern auszutauschen",
      },
      {
        label: "13:30-15:00 Uhr",
        value:
          "Werde zum Linux-Profi: Dateien und Verzeichnisse wie ein Boss verwalten - Entdecke die coolsten Linux-Anwendungen und was sie alles können. Richte dein eigenes System ein.",
      },
      {
        label: "15:00-16:00 Uhr",
        value:
          "Let's rock: Vertiefung des ersten Tages - Abschlussdiskussion und jede Menge Raum für deine Fragen",
      },
    ],
    header: "Tag 1 - Mittwoch (05.04.)",
  },
  {
    lines: [
      {
        label: "10:00-11:00 Uhr",
        value:
          "Zurück in der Linux-Welt: Recap des ersten Tages - Entdecke die vielen Möglichkeiten von Linux",
      },
      {
        label: "11:00-12:30 Uhr",
        value:
          "Textverarbeitung mit Linux: So einfach und doch so mächtig - Nutze Linux-Kommandos wie ein Profi und lass deine Freunde staunen",
      },
      {
        label: "12:30-13:30 Uhr",
        value: "Pause! Iss was, quatsche und lade deine Batterien wieder auf",
      },
      {
        label: "13:30-15:00 Uhr",
        value:
          "Verwaltung von Benutzern und Netzwerken: Zeig, was du drauf hast! - Konfiguration von Netzwerkeinstellungen leicht gemacht",
      },
      {
        label: "15:00-16:00 Uhr",
        value:
          "Zusammenfassung und Feedback-Runde - Lass uns den Kurs gemeinsam abschließen und tausche dich mit deinen Mitstreitern aus!",
      },
    ],
    header: "Tag 2 - Donnerstag (06.04.)",
  },
];

import React from "react";

export type BlogPostsProps = {
  posts?: Array<{ id: string; title: string; body: string }>;
};

const BlogPostsComponent: React.FC<BlogPostsProps> = ({ posts }) => {
  return (
    <div
      style={{
        height: "calc(60vh - 70px)",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      {posts ? (
        posts.map((post) => (
          <div key={post.id}>
            <h1>{post.title}</h1>
            <p>{post.body}</p>
          </div>
        ))
      ) : (
        <div>
          <h1>Zur Zeit gibt es keine Blog Posts</h1>
          <p>
            Wir entwickeln gerade ein neues Feature für unsere Website und
            werden in Kürze Blog Posts veröffentlichen können. So lange könnt
            ihr gerne hier verweilen...
          </p>
        </div>
      )}
    </div>
  );
};

export default BlogPostsComponent;

import React, { useEffect } from "react";
import "./index.css";
import imgOne from "../../assets/_NIG0395.jpg";
// import imgTwo from "../../assets/_NIG0295.jpg";
import imgThree from "../../assets/_NIG0670.jpg";
import { useElementOnScreen } from "../../hooks/use-element-on-screen";
import useCookie from "../../hooks/use-cookie";

const About: React.FC = () => {
  const elementScrollOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0.01,
  };
  const [rowOneRef, rowOneInView] =
    useElementOnScreen<HTMLDivElement>(elementScrollOptions);
  const [rowTwoRef, rowTwoInView] =
    useElementOnScreen<HTMLDivElement>(elementScrollOptions);
  const [rowThreeRef, rowThreeInView] =
    useElementOnScreen<HTMLDivElement>(elementScrollOptions);
  const [rowFourRef, rowFouInView] =
    useElementOnScreen<HTMLDivElement>(elementScrollOptions);

  useEffect(() => {
    const handleRefMapVisibility = (refMap: {
      ref: React.MutableRefObject<HTMLDivElement | null>;
      visible: boolean;
    }) =>
      refMap.visible
        ? refMap.ref.current?.classList.add("fade-in")
        : refMap.ref.current?.classList.remove("fade-in");
    [
      { ref: rowOneRef, visible: rowOneInView },
      { ref: rowTwoRef, visible: rowTwoInView },
      { ref: rowThreeRef, visible: rowThreeInView },
      { ref: rowFourRef, visible: rowFouInView },
    ].forEach(handleRefMapVisibility);
  }, [
    rowOneRef,
    rowOneInView,
    rowTwoRef,
    rowTwoInView,
    rowThreeRef,
    rowThreeInView,
    rowFourRef,
    rowFouInView,
  ]);

  const { isAccepted } = useCookie();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <div ref={rowOneRef} className="row mx-md-5 fade-out">
        <div className="col-sm-12 mb-3">
          <h1>Was machen wir?</h1>
        </div>
        <div className="col-md-5 mt-3 mt-md-0 ">
          <img
            src={imgThree}
            alt="Ein Bild unseres 3D-Druckers auf einem Tisch im Freien auf einer Veranstaltung."
          />
        </div>
        <div className={"col-sm-12 col-md-7 mt-5 mb-2"}>
          <p className="d-flex align-items-center h-100">
            Als junger Verein möchten wir Menschen für die Technik begeistern
            und ihnen die Möglichkeit geben, sich mit der Technik
            auseinanderzusetzen. Unser Makerspace bietet bereits Workshops und
            Projekten an, die sowohl praktische und anwendungsorientierte Themen
            als auch moderne Technologien abdecken. Unser Ziel ist es, unsere
            Palette an Angeboten weiter auszubauen und Technologie und Kultur
            miteinander zu verbinden, um zu zeigen, dass Technik für jeden von
            uns von Interesse sein kann.
            {/* brrr */}
          </p>
        </div>
        <div
          ref={rowTwoRef}
          className="row d-flex justify-content-center mt-5 fade-out"
        >
          <div className={"col-sm-12 col-md-7 mt-5"}>
            <p className="d-flex align-items-center h-100">
              Wir treffen uns jeden Mittwoch um 18:30 Uhr in der ehemaligen
              Hauptschule Nord im dritten Stockwerk (Hofenfelsstraße 53), wo wir
              Zugang zu Computern, Netzwerkkomponenten und sogar einem
              3D-Drucker haben, um gemeinsam Software, Netzwerkstrukturen und
              praktische Projekte zu entwickeln und uns weiterzuentwickeln. Wir
              sind immer auf der Suche nach neuen Mitgliedern, die ihre
              Leidenschaft für Technik mit uns teilen möchten. Ob Anfänger oder
              Fortgeschrittener, bei uns findet jeder die Möglichkeit, neue
              Fähigkeiten zu erlernen und sich weiterzuentwickeln.
            </p>
          </div>
          <div className="col-md-5">
            {isAccepted && (
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2604.1794367705!2d7.362643415568647!3d49.2540447807205!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4795d13107ae8697%3A0x7862d31278f39e97!2sKultur%20und%20Technik%20Zweibr%C3%BCcken%20e.V.!5e0!3m2!1sde!2sde!4v1679250451693!5m2!1sde!2sde"
                height="450"
                style={{ border: 0, width: "100%" }}
                loading="lazy"
              ></iframe>
            )}
          </div>
        </div>
        <div
          ref={rowFourRef}
          className="row d-flex justify-content-center fade-out mt-5"
        >
          <div className="col-md-5 mt-4">
            <img
              src={imgOne}
              alt="Ein Bild eines Netzwerk-Switches, dessen LAN-Ports zum kleinen Teil mit Netzwerk-Kabeln besetzt sind."
            />
          </div>
          <div className={"col-sm-12 col-md-7 mt-3"}>
            <p>
              Wir bieten Treffen, Workshops und Projekte, bei denen man die
              verschiedenen Aspekte der Technik kennenlernen und vertiefen kann.
              Von der Programmierung über die Elektrotechnik bis hin zu
              handwerklichen Tätigkeiten und dem Bau von Prototypen - bei uns
              ist für jeden etwas dabei. Als Mitglied unseres Vereins erhältst
              du Zugang zu unseren Räumlichkeiten, in denen du dich mit anderen
              Gleichgesinnten austauschen und gemeinsam an Projekten arbeiten
              kannst. Wir unterstützen uns gegenseitig, teilen unser Wissen und
              fördern so die Weiterbildung und Entwicklung aller Mitglieder. Wir
              freuen uns darauf, dich bei uns begrüßen zu dürfen und gemeinsam
              die Welt der Technik zu erkunden!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;

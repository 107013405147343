/* eslint-disable @typescript-eslint/no-explicit-any */
import { setIn } from 'final-form';

export const validateFinalForm = (schema: any) => {
  return async (values: any) => {
    try {
      await schema.validate(values, { abortEarly: false });
    } catch (e: any) {
      return e.inner.reduce((errors: any, error: any) => {
        return setIn(errors, error.path, error.message);
      }, {});
    }
  };
};

import React from "react";
import { useLinkClickHandler } from "react-router-dom";
import bgVideo from "../../assets/bg-video.mp4";
import pagesEnabled from "../../pages-enabled";
import "./index.css";

const Home: React.FC = () => {
  const handleEventButtonClick = useLinkClickHandler("landemic");
  const handleMenuButtonClick = useLinkClickHandler("drinkingmenu");

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <video
          src={bgVideo}
          autoPlay
          loop
          muted
          style={{
            width: "100vw",
            height: "calc(100vh - 70px)",
            objectFit: "cover",
          }}
        />
        <div
          style={{
            position: "absolute",
            paddingLeft: "1vw",
            overflow: "hidden",
          }}
        >
          <div className="container">
            <div className="row">
              <p className="display-1" style={{ fontWeight: "bold" }}>
                Kultur und Technik <br />
                <span style={{ color: "#ebe534" }}>Zweibrücken</span> e.V.
              </p>
            </div>
            <div className="row mt-2">
              <h6>Digitalisierung - Technologie - Kultur</h6>
            </div>
            {/* this should by dynamic - atm this is custom logic inside this component, but should be part of the app-config instead */}
            {pagesEnabled["landemic"] && (
              <div className="row mt-5">
                <div className="col-12">
                  <a
                    className="eventbtn"
                    onClick={(event) => handleEventButtonClick(event)}
                  >
                    <div>
                      LANDEMIC <br></br>10-11 Dez 2022
                    </div>
                  </a>
                </div>
              </div>
            )}
            {pagesEnabled["drinkingmenu"] && (
              <div className="row mt-5">
                <div className="col-12">
                  <a
                    className="eventbtn"
                    onClick={(event) => handleMenuButtonClick(event)}
                  >
                    <div>
                      Stadtfest 2023 <br />
                      Getränkekarte
                    </div>
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;

import React, { useEffect } from 'react';
import './index.css';
import { useElementOnScreen } from '../../hooks/use-element-on-screen';
import { LANDEMIC_INFOS } from '../../component-configs/landemic-infos';

const LandemicFlyer: React.FC = () => {
  return (
    <div className="container">
      <div className="row mb-5">
        <div className="col-12">
          <h3>Die erste Welle</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <p>
            Kultur und Technik Zweibrücken e.V. lädt zur LAN-Party ein!
            <br />
            Du hast Bock deine liebsten Games nicht mehr alleine in deinem
            Zimmer zu spielen?
            <br />
            Deine Internetverbindung macht deinen Discord-Call zum Wörterraten?
            <br />
            Du kommst mit deinem Projekt zurzeit nicht weiter und brauchst
            einfach mal frischen Wind?
          </p>
        </div>
        <div className="col-md-6">
          <p>
            Hier kannst du 2 Tage lang mit Gleichgesinnten ganz dem digitalen
            Virus verfallen, ohne dass der Arzt kommt! (;
            <br />
            Weitere Infos und das Anmeldeformular findest du unten.
            <br />
            <br />
            <br />
            Wir freuen uns auf dich!
          </p>
        </div>
      </div>
    </div>
  );
};

export const LandemicFlyerHeader: React.FC = () => {
  return (
    <div className="container card bg-dark">
      <div className="row">
        <div className="col-12">
          <a className="flyer-header">
            <div>LANDEMIC</div>
          </a>
        </div>
      </div>
    </div>
  );
};

export const LandemicFlyerListItems: React.FC = () => {
  return (
    <div className="container card bg-dark">
      {LANDEMIC_INFOS.map(({ lines, header }) => (
        <div key={header} className="row">
          <div className="col-12">
            <h5 className="card-title pt-3 pb-2">
              <strong className="flyer-text">{header}</strong>
            </h5>
            {lines.map((props, index) => (
              <LandemicFlyerListGroupItem key={index} {...props} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export type LandemicFlyerListGroupItemProps =
  | {
      label: string;
      value: string;
    }
  | { content: string };

const isContentElement = (
  props: LandemicFlyerListGroupItemProps
): props is { content: string } =>
  (props as { content: string }).content !== undefined;

export const LandemicFlyerListGroupItem: React.FC<
  LandemicFlyerListGroupItemProps
> = (props) => {
  const elementScrollOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.3,
  };
  const [rowOneRef, rowOneInView] =
    useElementOnScreen<HTMLDivElement>(elementScrollOptions);
  useEffect(() => {
    const handleRefMapVisibility = (refMap: {
      ref: React.MutableRefObject<HTMLDivElement | null>;
      visible: boolean;
    }) => {
      refMap.visible
        ? refMap.ref.current?.classList.add('fade-in-info-item')
        : refMap.ref.current?.classList.remove('fade-in-info-item');
    };
    [{ ref: rowOneRef, visible: rowOneInView }].forEach(handleRefMapVisibility);
  }, [rowOneRef, rowOneInView]);

  return (
    <div
      ref={rowOneRef}
      className="row list-group-item bg-dark fade-out-info-item"
    >
      {isContentElement(props) ? (
        <div className="col-12">
          <span>{props.content}</span>
        </div>
      ) : (
        <>
          <div className="col-md-4">
            <span>{props.label}</span>
          </div>
          <div className="col-md-8">
            <span>{props.value}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default LandemicFlyer;

import React from "react";

export const Imprint: React.FC = () => (
  <div>
    <div className="bg-dark pt-3 px-5">
      <div
        className="row"
        style={{ marginTop: "30px", height: "calc(100vh - 130px)" }}
      >
        <div className="col-12 d-flex mb-3">
          <h3>Impressum</h3>
        </div>
        <div className="col-md-12">
          <h5>Angaben gem&auml;&szlig; &sect; 5 TMG</h5>
          <p style={{ fontSize: "0.7rem" }}>
            Kultur und Technik Zweibr&uuml;cken e.V.
            <br />
            Habichtstra&szlig;e 28
            <br />
            66497 Contwig
          </p>

          <p style={{ fontSize: "0.7rem" }}>
            Vereinsregister: VR 30614
            <br />
            Registergericht: Amtsgericht Zweibr&uuml;cken
          </p>

          <p style={{ fontSize: "0.7rem" }}>
            <strong>Vertreten durch:</strong>
            <br />
            Andreas K&ouml;lsch, Dennis Schmitt, Nico Kolf
          </p>

          <h5>Kontakt</h5>
          <p style={{ fontSize: "0.7rem" }}>
            Telefon: +49 (0) 1726851035
            <br />
            E-Mail: info.ktzweibruecken@gmail.com
          </p>

          <h5>EU-Streitschlichtung</h5>
          <p style={{ fontSize: "0.7rem" }}>
            Die Europ&auml;ische Kommission stellt eine Plattform zur
            Online-Streitbeilegung (OS) bereit:{" "}
            <a
              href="https://ec.europa.eu/consumers/odr/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://ec.europa.eu/consumers/odr/
            </a>
            .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
          </p>

          <h5>
            Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
          </h5>
          <p style={{ fontSize: "0.7rem" }}>
            Wir sind nicht bereit oder verpflichtet, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
          </p>
        </div>

        {/* Align new row to bottom */}
        <div className="col-12 d-flex mt-5">
          <p style={{ fontSize: "0.7rem" }}>
            {new Date().getFullYear()} Kultur und Technik Zweibrücken e.V.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default Imprint;

import { PageRoutes } from "./page-types";

const pageRoutes: PageRoutes = {
  main: "/",
  landemic: "/landemic",
  landemicVerification: "/landemic/verification/:code",
  it2bib: "/it2bib",
  privacyPolicy: "/privacy-policy",
  blogposts: "/blogposts",
  drinkingmenu: "/drinkingmenu",
};

export default pageRoutes;

import { AppContentConfig } from "../page-types";

export const appContentConfig: AppContentConfig = {
  main: {
    displayName: "Hauptseite",
    parts: [
      {
        id: "home",
        displayName: "Hauptseite",
        renderPosition: "above container",
        style: {
          paddingTop: "0vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        showInPageNavigation: true,
      },
      {
        id: "about",
        displayName: "Über uns",
        renderPosition: "inside container",
        style: {
          paddingTop: "15vh",
          marginBottom: "8vh",
          // make backgground shine through the container
          backgroundColor: "rgba(105, 126, 147, 0.12)",
          paddingBottom: "40vh",
          // make bottom border curved
          clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 10vh))",
        },
        showInPageNavigation: true,
      },
      {
        id: "contact",
        displayName: "Kontakt",
        renderPosition: "inside container",
        style: {
          marginTop: "15vh",
        },
        showInPageNavigation: true,
      },
      {
        id: "return-to-home",
        renderPosition: "inside container",
        style: {
          marginTop: "15vh",
          marginBottom: "8vh",
        },
      },
      {
        id: "imprint",
        displayName: "Impressum",
        renderPosition: "inside footer",
        showInPageNavigation: true,
      },
    ],
  },
  landemic: {
    displayName: "Landemic",
    parts: [
      {
        id: "landemic-flyer-header",
        style: { paddingTop: "7vw" },
        renderPosition: "above container",
      },
      {
        id: "landemic-flyer",
        style: { paddingTop: "15vh" },
        renderPosition: "inside container",
      },
      {
        id: "landemic-flyer-info-list",
        style: { paddingTop: "30vh" },
        renderPosition: "inside container",
        displayName: "Flyer",
        showInPageNavigation: true,
      },
      {
        id: "landemic-signup-form",
        renderPosition: "inside container",
        displayName: "Anmeldung",
        style: { paddingTop: "10vh" },
        showInPageNavigation: true,
      },
      {
        id: "imprint",
        displayName: "Impressum",
        renderPosition: "inside footer",
        style: { paddingTop: "15vh" },
        showInPageNavigation: true,
      },
    ],
  },
  landemicVerification: {
    displayName: "Landemic",
    parts: [
      {
        id: "landemic-verification",
        style: {
          marginTop: "25vh",
        },
        renderPosition: "inside container",
      },
      {
        id: "imprint",
        displayName: "Impressum",
        renderPosition: "inside footer",
        style: {
          marginTop: "25vh",
        },
        showInPageNavigation: true,
      },
    ],
  },
  it2bib: {
    displayName: "IT2BIB",
    visibleInNavbar: true,
    parts: [
      {
        id: "it2bib-flyer",
        style: { paddingTop: "15vw" },
        renderPosition: "inside container",
      },
      {
        id: "it2bib-flyer-info-list",
        style: { paddingTop: "30vh" },
        renderPosition: "inside container",
      },
      {
        id: "imprint",
        displayName: "Impressum",
        renderPosition: "inside footer",
        style: {
          marginTop: "25vh",
        },
        showInPageNavigation: true,
      },
    ],
  },
  privacyPolicy: {
    displayName: "Datenschutz",
    parts: [
      {
        id: "privacy-policy",
        renderPosition: "inside container",
      },
      {
        id: "imprint",
        displayName: "Impressum",
        renderPosition: "inside footer",
        style: {
          marginTop: "25vh",
        },
      },
    ],
  },
  blogposts: {
    displayName: "Blog",
    visibleInNavbar: true,
    parts: [
      {
        id: "blogposts",
        renderPosition: "inside container",
        style: {
          marginTop: "15vh",
        },
      },
      {
        id: "imprint",
        displayName: "Impressum",
        renderPosition: "inside footer",
        style: {
          marginTop: "25vh",
        },
      },
    ],
  },
  drinkingmenu: {
    displayName: "Getränkekarte",
    visibleInNavbar: true,
    parts: [
      {
        id: "drinkingmenu",
        renderPosition: "inside container",
        style: {
          marginTop: "15vh",
        },
      },
      {
        id: "imprint",
        displayName: "Impressum",
        renderPosition: "inside footer",
        style: {
          marginTop: "25vh",
        },
      },
    ],
  },
};

import React, { useCallback } from "react";
import "./App.css";
import { Element } from "react-scroll";
import "react-toastify/dist/ReactToastify.css";
import { PageComponentParts, Page } from "./page-types";
import componentMap from "./page-component-map";
import { ToastContainerConfig } from "./component-configs/toast-container-config";
import { ToastContainer } from "react-toastify";

export type AppProps = { currentPage: Page };

const App: React.FC<AppProps> = ({ currentPage }) => {
  const renderPageComponents = useCallback(
    (renderPosition: PageComponentParts["renderPosition"]) =>
      currentPage.parts
        .filter((part) => part.renderPosition === renderPosition)
        .map((part) => ({ ...part, component: componentMap[part.id] }))
        .map(({ component: ElementContent, style, id, renderPosition }) => (
          <Element name={id} key={id}>
            <div style={style}>
              {renderPosition === "above container" && <ElementContent />}
              {renderPosition === "inside container" && (
                <div className="container mx-sx-0">
                  <ElementContent />
                </div>
              )}
              {renderPosition === "inside footer" && (
                <footer className="footer">
                  <ElementContent />
                </footer>
              )}
            </div>
          </Element>
        )),
    [currentPage]
  );

  return (
    <>
      <ToastContainer {...ToastContainerConfig} />
      {renderPageComponents("above container")}
      {renderPageComponents("inside container")}
      {renderPageComponents("inside footer")}
    </>
  );
};

export default App;
